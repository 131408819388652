<template>
  <kdx-content-bar ref="content_bar" :loading="loading">
    <div class="content">
      <kdx-form-title content="安全"></kdx-form-title>
      <Form class="form" :label-width="120" @submit.native.prevent>
        <FormItem label="登录手机号：">
          <span class="user-mobile">{{ mobile | hidephonecenter }}</span>
          <log-button type="text" @click="showModal = true">修改</log-button>
        </FormItem>
        <FormItem label="微信：">
          <Button type="text" @click="bindHandle">{{
            bind.wx ? "已绑定" : "绑定"
          }}</Button>
        </FormItem>
        <kdx-form-title content="消息通知"></kdx-form-title>
        <FormItem label="微信通知：">
          <i-switch
            size="large"
            v-model="inform.wx"
            @on-change="(v) => change(v, 'notice_wx')"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
          <p class="tip">使用微信通知请先绑定微信</p>
        </FormItem>
        <FormItem label="短信通知：">
          <i-switch
            size="large"
            v-model="inform.sms"
            @on-change="(v) => change(v, 'notice_sms')"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
      </Form>
    </div>

    <alone-modal v-model="showModal" title="修改手机号" class="modal">
      <Form :model="model" ref="form" :rules="rules" :label-width="100">
        <form-item label="手机号码" prop="mobile">
          <log-input
            :class="[focusInput === 'Mobile' ? 'input-focus' : '']"
            @on-focus="onFocus('Mobile')"
            @on-blur="onBlur"
            v-model="model.mobile"
            placeholder="请输入手机号码"
            maxlength="11"
          />
        </form-item>

        <form-item label="验证码" prop="code" style="margin-bottom: 20px">
          <code-input
            :class="[focusInput === 'code' ? 'input-focus' : '']"
            @on-focus="onFocus('code')"
            @on-blur="onBlur"
            v-model="model.code"
            placeholder="请输入验证码"
            :btnText="sms.timerTxt"
            :loading="sms.loading"
            :disabled="sms.time > 0"
            @on-send-code="sendCode"
          />
        </form-item>
      </Form>
      <template #footer>
        <div class="modal-btn" @click="handleSave">确认</div>
      </template>
    </alone-modal>

    <kdx-modal-frame
      v-model="showBindModal"
      title="绑定微信"
      :width="450"
      @on-cancel="cancel"
      :loading="modalLoading"
    >
      <div class="bind-modal">
        <p>使用微信扫码绑定</p>
        <img class="qrcode" :src="qrcode" alt="" />
      </div>
      <template #footer>
        <span></span>
      </template>
    </kdx-modal-frame>
  </kdx-content-bar>
</template>

<script>
import AloneModal from "../components/Modal.vue";
import LogInput from "@/views/login/components/LogInput.vue";
import CodeInput from "@/views/login/components/CodeInput.vue";
import LogButton from "./components/Button.vue";
import GetSms from "@/helpers/getSms";
import { requiredRule, mobileRule } from "@/helpers/validateRule.js";
import { throttle } from "@/helpers/common";
import QRCode from "qrcode";
export default {
  components: {
    LogButton,
    LogInput,
    CodeInput,
    AloneModal,
  },
  data() {
    return {
      loading: false,
      model: {
        mobile: "",
        code: "",
      },
      focusInput: "",
      showModal: false,
      sms: new GetSms({ id: 2, repeatTime: 60 }),
      rules: {
        mobile: mobileRule,
        code: requiredRule("请输入验证码"),
      },
      bind: {
        wx: false,
      },
      showBindModal: false,
      qrcode: "",
      modalLoading: false,
      timer: null,
      inform: {
        wx: false,
        sms: false,
      },
    };
  },
  computed: {
    mobile() {
      return this.$store.state.alone.user.mobile;
    },
  },
  async created() {
    await this.$store.dispatch("alone/getUserInfo");
    const { wxbinding, notice_sms, notice_wx } = this.$store.state.alone.user;
    this.bind.wx = !!wxbinding;
    this.inform.sms = !!notice_sms;
    this.inform.wx = !!notice_wx;
  },
  methods: {
    async change(status, key) {
      await this.$api.user.changeNotice(
        { type: key, state: status ? 1 : 0 },
        { toast: 1 }
      );
    },

    cancel() {
      this.showBindModal = false;
      if (this.timer) {
        clearInterval(this.timer);
      }
    },
    async bindHandle() {
      if (this.bind.wx) return;
      this.showBindModal = true;
      this.modalLoading = true;
      this.qrcode = await QRCode.toDataURL(
        `${this.$baseUrl}/shopapi/member/bindingWx/access_token/${this.$store.state.token}`
      );
      this.modalLoading = false;
      this.timer = setInterval(this.getBind, 5000);
    },
    async getBind() {
      await this.$api.user.synbindingWx({}, { loading: true, toast: 2 });
      this.bind.wx = true;

      clearInterval(this.timer);
      setTimeout(() => {
        this.showBindModal = false;
        this.$Message.success("绑定成功");
      }, 500);
    },
    sendCode() {
      this.$refs.form.validateField("mobile", (err) => {
        if (err) {
          this.$Message.warning(err);
          return;
        }
        this.sms.sendCode(() => {
          return this.$api.auth
            .getSmsCode({ mobile: this.model.mobile }, { toast: 1 })
            .then((res) => {
              this.$store.commit("setTempToken", res.temp_token);
              return Promise.resolve("success");
            });
        });
      });
    },
    onFocus(focusName) {
      this.focusInput = focusName;
    },
    onBlur() {
      console.log("blur");
      this.focusInput = "";
    },
    handleSave() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        const { code } = this.model;
        this.$api.user
          .changePhone(
            { smscode: code, temp_token: this.$store.state.temp_token },
            { toast: 1 }
          )
          .then(() => {
            this.showModal = false;
            this.$store.dispatch("alone/getUserInfo");
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  border-radius: 2px;
  margin: 20px auto 0;
  background-color: #ffffff;
  padding: 20px 40px;
  min-height: calc(100% - 20px) !important;
}

.modal /deep/ .ivu-modal-body {
  height: unset;
}

/deep/ .ivu-tabs-bar {
  margin-bottom: 40px;
  padding: 0 30px;

  .ivu-tabs-tab {
    padding: 12px 30px;
    @include font-18-25-bold;

    .icon-tishi-prompt {
      position: absolute;
      right: 10px;
      color: $danger-color;
      font-size: 18px;
    }
  }
}

/deep/ .ivu-form-item-label {
  padding-right: 10px !important;
}

.user-mobile {
  margin-right: 10px;
  text-align: center;
  line-height: 2.5;
  display: inline-block;
}

.modal-btn {
  cursor: pointer;
  user-select: none;
}

.bind-modal {
  text-align: center;
  padding-top: 20px;

  p {
    font-size: 16px;
  }
}

.qrcode {
  width: 200px;
}

.tip {
  color: #f14242;
}
</style>