var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[(_vm.type !== 'text')?_c('span',{class:[
        `log-btn log-btn-${_vm.type}`,
        `log-btn--${_vm.size}`,
        `log-btn--${_vm.shape}`,
        {
            [`log-btn--disabled`]: _vm.disabled,
            [`log-btn--long`]: _vm.long,
        },
    ],on:{"click":_vm.handleClick}},[_vm._t("default")],2):_c('a',{staticClass:"log-btn-text",on:{"click":_vm.goPage}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }