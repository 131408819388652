<template>
    <Modal :title="title" :transfer="transfer" :value="value" :width="width" @input="inputHandler">
        <slot></slot>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </Modal>
</template>

<script>
export default {
    name: 'AModal',
    props: {
        title: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean
        },
        // 是否放置于body
        transfer: {
            type: Boolean,
            default: false
        },
        width: {
            type: [String, Number],
            default: 520
        }
    },
    methods: {
        inputHandler(e) {
            this.$emit('input', e);
        }
    }
};
</script>


<style lang="scss" scoped>
/deep/ .ivu-modal {
    .ivu-modal-header {
        height: 60px;
        padding: 20px;
    }

    .ivu-modal-close {
        top: 15px;
        right: 15px;
    }

    .ivu-modal-content {
        border-radius: 16px;
    }

    .ivu-modal-body {
        padding: 20px 20px 20px 40px;
        height: 560px;
        overflow-y: scroll;
    }

    .ivu-modal-footer {
        padding: 16px;
        text-align: center;
    }
}
</style>